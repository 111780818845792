import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import i18next from 'i18next';

class ProductSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = { value: '' };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        this.setState({ value: event.target.value });
    }

    handleSubmit(event) {
        var siteName = window.location.href.split('/').slice(0, 3).join('/');
        window.location.href = siteName + '/filteredProducts/?search=' + this.state.value;
        event.preventDefault();
    }

    render() {
        return (
            <div className="widget widget_search">
                <h4 className="widget-title">
                    {' '}
                    <Text field={this.props.fields.title} />
                </h4>
                <form id="searchform" onSubmit={this.handleSubmit}>
                    <label className="sr-only">{i18next.t('search')}</label>
                    <input
                        type="search"
                        id="search"
                        name="search"
                        className="form-control"
                        value={this.state.value}
                        placeholder="Search something…"
                        onChange={this.handleChange}
                    />
                    <input
                        type="submit"
                        id="sidebarSearchSubmit"
                        className="hidden"
                        name="submit"
                        value="Search"
                    />
                </form>
            </div>
        );
    }
}

export default ProductSearch;
