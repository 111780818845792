import React from 'react';
import { Image, Text, RichText, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import './titleAndDescAndImage.scss';

const TitleAndDescriptionAndImage = (props) => {
    return (
        <div className="row_custom_small box-1 tadai">
            <div className="row_custom_small section3">
                <div className="col-md-12 pad text-center">

                    <font color={props.fields.TitleColor && props.fields.TitleColor.fields.cssColor.value}>
                        {props.fields.Title && props.fields.Title.value !== "" && <Text field={props.fields.Title} tag="h1" className="freeland"/>}
                        {props.fields.ImageLocation && props.fields.Image && props.fields.ImageLocation.fields.title.value === 'Before Description' && (
                            <Image loading="lazy" field={props.fields.Image} editable={true} className="img_custom_full"/>
                        )}
                        {props.fields.Description && props.fields.Description.value !== "" && <blockquote> <RichText field={props.fields.Description} tag="p" className="helv_lt"/> </blockquote> }
                    </font>
                    {props.fields.ImageLocation && props.fields.Image && props.fields.ImageLocation.fields.title.value !== 'Before Description' && (
                        <Image loading="lazy" field={props.fields.Image} editable={true} className="img_custom_full"/>
                    )}
                </div>
            </div>
        </div>
    );
};

export default withSitecoreContext()(TitleAndDescriptionAndImage);
