import React from 'react';
import { RichText, Text, withSitecoreContext, Image } from '@sitecore-jss/sitecore-jss-react';
import './ProductPIL.scss';
import i18next from 'i18next';
import NavLinkAdv from '../../utils/NavLinkAdv';
import score_a from '../../assets/images/PIL/SCORE_A.svg';
import score_b from '../../assets/images/PIL/SCORE_B.svg';
import score_c from '../../assets/images/PIL/SCORE_C.svg';
import score_d from '../../assets/images/PIL/SCORE_D.svg';
import score_e from '../../assets/images/PIL/SCORE_E.svg';
import icon_co2 from '../../assets/images/PIL/CO2.svg';
import icon_drop from '../../assets/images/PIL/DROP.svg';

const ProductPIL = (props) => {
    const productData = props.sitecoreContext.route.fields;

    let diagramImg;
    const footprintScore = productData.GlobalFootprintScore.value;
    if (footprintScore === 'A') {
        diagramImg = score_a;
    } else if (footprintScore === 'B') {
        diagramImg = score_b;
    } else if (footprintScore === 'C') {
        diagramImg = score_c;
    } else if (footprintScore === 'D') {
        diagramImg = score_d;
    } else if (footprintScore === 'E') {
        diagramImg = score_e;
    }

    // console.log(props.sitecoreContext.route.fields);

    let footnoteNumberTop = 0;
    let footnoteNumberBottom = 0;
    function incrementalFootnoteNumber(position) {
        if (position === 'top') {
            footnoteNumberTop = footnoteNumberTop + 1;
            return footnoteNumberTop;
        } else if (position === 'bottom') {
            footnoteNumberBottom = footnoteNumberBottom + 1;
            return footnoteNumberBottom;
        }
    }

    function checkIfValid(data) {
        if (data && data !== 0 && data !== '0') return true;
        else return false;
    }

    return (
        <div className="sustainability">
            <RichText tag="div" style={{ marginBottom: '1em' }} field={productData.Description1} />
            <RichText tag="div" field={productData.Description2} />
            <div className="sustainability__section">
                <h4 className="sustainability__section-title">
                    {i18next.t('PIL-global-impact-title')}
                </h4>
                <div className="sustainability__footprint">
                    <div className="sustainability__diagram">
                        <img
                            data-sizes="(max-width: 767px) 50vw, 25vw"
                            alt={
                                'Sustainability Grade ' +
                                productData.GlobalFootprintScore.value +
                                ''
                            }
                            data-aspectratio="256/309"
                            src={diagramImg}
                            className=" lazyloaded"
                            sizes="(max-width: 767px) 50vw, 25vw"
                        />
                    </div>
                    <div className="sustainability__indicators">
                        <div className="sustainability__indicator">
                            <h6 className="sustainability__footprint-title">
                                {i18next.t('PIL-carbon-footprint')}
                            </h6>
                            <div
                                className={
                                    'sustainability__indicator-line position-' +
                                    productData.CarbonFootprintScore.value
                                }
                            >
                                <img
                                    src={icon_co2}
                                    alt="co2"
                                    className="icon sustainability__indicator-icon"
                                />
                            </div>
                            <div className="sustainability__indicator-information carbon">
                                <p>
                                    <span>{productData.CarbonFootprintPerUsageDose.value}</span>
                                    <sup>({incrementalFootnoteNumber('top')})</sup> par utilisation
                                </p>
                                <p>
                                    <span>{productData.CarbonFootprintPerDisplayedDose.value}</span>{' '}
                                    pour {productData.CarbonFootprintDisplayedDoseQty.value}
                                    {productData.CarbonFootprintDisplayedDoseUnit.value}
                                </p>
                            </div>
                        </div>
                        <div className="sustainability__indicator">
                            <h6 className="sustainability__footprint-title">
                                {i18next.t('PIL-water-footprint')}
                            </h6>
                            <div
                                className={
                                    'sustainability__indicator-line position-' +
                                    productData.WaterFootprintScore.value
                                }
                            >
                                <img
                                    src={icon_drop}
                                    alt="co2"
                                    className="icon sustainability__indicator-icon"
                                />
                            </div>
                            <div className="sustainability__indicator-information">
                                <p>
                                    <span>{productData.WaterFootprintPerUsageDose.value}</span>
                                    <sup>({incrementalFootnoteNumber('top')})</sup> par utilisation
                                </p>
                                <p>
                                    <span>{productData.WaterFootprintPerDisplayedDose.value}</span>{' '}
                                    pour {productData.WaterFootprintDisplayedDoseQty.value}
                                    {productData.WaterFootprintDisplayedDoseUnit.value}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Optional, in case of RechargeableRefillable */}
                {productData.RechargeableRefillable.value && (
                    <RichText tag="div" field={productData.Specification} />
                )}
                {/* Optional, in case of D score */}
                {productData.GlobalFootprintScore.value >= 'D' && (
                    <RichText tag="div" field={productData.Description3} />
                )}
            </div>
            <div className="sustainability__section">
                <div className="sustainability__text-block">
                    <h4 className="sustainability__section-title">
                        {i18next.t('PIL-manufacturing-conditions-title')}
                    </h4>
                    <div>
                        <RichText
                            style={{ display: 'inline-block' }}
                            tag="p"
                            field={productData.PlantMadeInCountryDescription}
                        />
                        {productData.MadeInSubcontractorPlant.value && (
                            <sup>({incrementalFootnoteNumber('top')})</sup>
                        )}
                        {checkIfValid(productData.PlantWasteRecyclingRate.value) && (
                            <p className="sustainability__table">
                                <span>{i18next.t('PIL-manufacturing-waste')} :</span>{' '}
                                <span>{productData.PlantWasteRecyclingRate.value}%</span>
                            </p>
                        )}
                        {checkIfValid(productData.PlantRenewableEnergyRate.value) && (
                            <p className="sustainability__table">
                                <span>{i18next.t('PIL-manufacturing-energy')} :</span>{' '}
                                <span>{productData.PlantRenewableEnergyRate.value}%</span>
                            </p>
                        )}
                    </div>
                </div>
                <div className="sustainability__text-block">
                    <h4 className="sustainability__section-title">
                        {i18next.t('PIL-packaging-impact-title')}
                    </h4>

                    {checkIfValid(productData.ForestCertificationRate.value) && (
                        <p className="sustainability__table">
                            <span>{i18next.t('PIL-packaging-paper')} :</span>{' '}
                            <span>{productData.ForestCertificationRate.value}%</span>
                        </p>
                    )}
                    <p></p>
                    {checkIfValid(productData.RecycledMaterialRate.value) && (
                        <p className="sustainability__table">
                            <span className="packing-type">
                                {productData.MainComponentFamilyPCR.value}{' '}
                                {i18next.t('PIL-packaging-recycled-material')}
                            </span>{' '}
                            <span>
                                <sup className="sup-type">({incrementalFootnoteNumber('top')})</sup>
                            </span>
                            :<span> {productData.RecycledMaterialRate.value}%</span>
                        </p>
                    )}

                    <p className="sustainability__table">
                        <span>
                            <span>{i18next.t('PIL-packaging-recyclable')}</span>{' '}
                            <span>
                                <sup className="sup-type">({incrementalFootnoteNumber('top')})</sup>
                            </span>
                            :
                        </span>{' '}
                        <span>{productData.MainComponentRecyclability.value ? 'oui' : 'non'}</span>
                    </p>
                    <p className="sustainability__table">
                        <span>{i18next.t('PIL-packaging-refillable')} :</span>{' '}
                        <span>{productData.RechargeableRefillable.value ? 'oui' : 'non'}</span>
                    </p>
                </div>
            </div>
            <div className="sustainability__section">
                <div className="sustainability__text-block">
                    <h4 className="sustainability__section-title">
                        {i18next.t('PIL-social-impact-title')}
                    </h4>

                    <RichText
                        style={{ marginBottom: '1em' }}
                        tag="div"
                        field={productData.Social1}
                    />

                    {checkIfValid(productData.NbOfInclusiveSuppliers.value) && (
                        <div>
                            {productData.NbOfInclusiveSuppliers.value}{' '}
                            <RichText style={{ display: 'inline' }} field={productData.Social2} />
                        </div>
                    )}
                </div>
                <div className="sustainability__text-block">
                    <div className="sustainability__footnote">
                        <sup>({incrementalFootnoteNumber('bottom')})</sup>{' '}
                        <RichText field={productData.FootNote1} />
                    </div>
                    <div className="sustainability__footnote">
                        <sup>({incrementalFootnoteNumber('bottom')})</sup>{' '}
                        <RichText field={productData.FootNote2} />
                    </div>
                    {/* Optional footnote */}
                    {productData.MadeInSubcontractorPlant.value && (
                        <div className="sustainability__footnote">
                            <sup>({incrementalFootnoteNumber('bottom')})</sup>{' '}
                            <RichText field={productData.FootNote5} />
                        </div>
                    )}
                    {/* Optional footnote */}
                    {checkIfValid(productData.RecycledMaterialRate.value) && (
                        <div className="sustainability__footnote">
                            <sup>({incrementalFootnoteNumber('bottom')})</sup>{' '}
                            <RichText field={productData.FootNote3} />
                        </div>
                    )}
                    <div className="sustainability__footnote">
                        <sup>({incrementalFootnoteNumber('bottom')})</sup>{' '}
                        <RichText field={productData.FootNote4} />
                    </div>
                </div>
                <div className="sustainability__text-block">
                    <RichText
                        style={{ marginBottom: '1em' }}
                        tag="div"
                        field={productData.Impact}
                    />
                    <NavLinkAdv
                        className="sustainability__button"
                        to={productData.ImpactURL.value.href}
                    >
                        {i18next.t('PIL-learnmore-label')}
                    </NavLinkAdv>
                </div>
            </div>
        </div>
    );
};

export default withSitecoreContext()(ProductPIL);


