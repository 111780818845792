import React from 'react';
import { Text, RichText, Image } from '@sitecore-jss/sitecore-jss-react';
import NavLinkAdv from '../../utils/NavLinkAdv';
import './rowBlock.scss';

class RowBlock extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        let leftcssStyle = 'col-md-5 c-l img-cn';
        let rightcssStyle = 'col-md-7 c-r text-cn';

        if (this.props.rows.fields.blockStyle !== null) {
            leftcssStyle = this.props.rows.fields.blockStyle.fields.leftcssStyle.value;
            rightcssStyle = this.props.rows.fields.blockStyle.fields.rightcssStyle.value;
        }

        const isClasses = 'row_custom_small item__row__custom rowBlock inverse__custom';
        const elseClasses = 'row_custom_small item__row__custom rowBlock';

        if (leftcssStyle.includes('text-cn')) {
            return (
                <div
                    className={`${
                        this.props.rows.fields.blockStyle !== null &&
                        rightcssStyle === 'col-md-7 c-r img-cn'
                            ? isClasses
                            : elseClasses
                    }`}
                >
                    <div className={leftcssStyle}>
                        <div>
                            <div>
                                <Image
                                    loading="lazy"
                                    field={
                                        this.props.rows.fields && this.props.rows.fields.blockLogo
                                    }
                                />
                                <Text
                                    field={
                                        this.props.rows.fields && this.props.rows.fields.blockTitle
                                    }
                                    tag={'h2'}
                                />
                                <RichText
                                    field={
                                        this.props.rows.fields &&
                                        this.props.rows.fields.blockContent
                                    }
                                    className={'desc_row_block'}
                                />
                                {this.props.rows.fields &&
                                    this.props.rows.fields.blockButton &&
                                    this.props.rows.fields.blockButton.value.href &&
                                    this.props.rows.fields.blockButton.value.text && (
                                        <NavLinkAdv
                                            to={
                                                this.props.rows.fields &&
                                                this.props.rows.fields.blockButton.value.href
                                            }
                                            className="link"
                                        >
                                            {this.props.rows.fields.blockButton.value.text}
                                            <i className="fa fa-angle-right"></i>
                                        </NavLinkAdv>
                                    )}
                            </div>
                        </div>
                    </div>
                    <div className={rightcssStyle}>
                        <Image
                            loading="lazy"
                            field={this.props.rows.fields && this.props.rows.fields.blockImage}
                            className="img_custom_full"
                        />
                    </div>
                </div>
            );
        } else {
            return (
                <div
                    className={`${
                        this.props.rows.fields.blockStyle !== null &&
                        rightcssStyle === 'col-md-7 c-r img-cn'
                            ? isClasses
                            : elseClasses
                    }`}
                >
                    <div className={leftcssStyle}>
                        <Image
                            loading="lazy"
                            field={this.props.rows.fields && this.props.rows.fields.blockImage}
                            className="img_custom_full"
                        />
                    </div>
                    <div className={rightcssStyle}>
                        <div>
                            <div>
                                <Image
                                    loading="lazy"
                                    field={
                                        this.props.rows.fields && this.props.rows.fields.blockLogo
                                    }
                                />
                                <Text
                                    field={
                                        this.props.rows.fields && this.props.rows.fields.blockTitle
                                    }
                                    tag={'h2'}
                                />
                                <RichText
                                    field={
                                        this.props.rows.fields &&
                                        this.props.rows.fields.blockContent
                                    }
                                    className={'desc_row_block'}
                                />
                                {this.props.rows.fields &&
                                    this.props.rows.fields.blockButton &&
                                    this.props.rows.fields.blockButton.value.href &&
                                    this.props.rows.fields.blockButton.value.text && (
                                        <NavLinkAdv
                                            to={
                                                this.props.rows.fields &&
                                                this.props.rows.fields.blockButton.value.href
                                            }
                                            className="link"
                                        >
                                            {this.props.rows.fields.blockButton.value.text}
                                            <i className="fa fa-angle-right"></i>
                                        </NavLinkAdv>
                                    )}
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

export default RowBlock;
