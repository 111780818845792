import React from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import Moment from 'react-moment';
import ReactComponent from '../../ReactComponent';
import NavLinkAdv from '../../utils/NavLinkAdv';
import i18next from 'i18next';
import { Query } from 'react-apollo';
import { loader as gqlLoader } from 'graphql.macro';
import { DotLoader } from 'react-spinners';
import Pagination from '../../utils/Pagination';

const PostsListQuery = gqlLoader('./postsListQuery.graphql');

class PostsList extends ReactComponent {
    constructor(props) {
        super(props);
        this.state = {
            after: '0',
            nbOfItemsToShow: parseInt(this.props.fields.postPerPage.value),
        };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(data) {
        this.setState({
            after: data.toString(),
        });
    }

    render() {
        let tagsLink = this.props.fields.tagsLink.value.href;
        return (
            <Query
                query={PostsListQuery}
                variables={{
                    indexname: this.indexName,
                    rootPath: 'sitecore/content/' + this.appName + '/',
                    first: this.state.nbOfItemsToShow,
                    after: this.state.after,
                }}
            >
                {({ loading, error, data }) => {
                    if (loading)
                        return (
                            <div
                                className="sweet-loading"
                                style={{ marginTop: '50px', marginBottom: '50px' }}
                            >
                                <DotLoader
                                    sizeUnit={'px'}
                                    size={25}
                                    color={'#007FC1'}
                                    loading={!this.state.isLoaded}
                                />
                            </div>
                        );

                    if (error) return <div>Error: {error.message}</div>;

                    let totalItems = data.search.results.totalCount;

                    return (
                        <div className="posts" data-layout="default">
                            <div className="posts-wrap posts-layout-default">
                                {data && data.search.results.items ? (
                                    data.search.results.items.map((post, index) => (
                                        <div className="hentry" key={`post-${index}`}>
                                            <div className="hentry-wrap">
                                                <div className="entry-featured">
                                                    <NavLinkAdv to={post.item && post.item.url}>
                                                        <img
                                                            loading="lazy"
                                                            src={
                                                                post.item.postThumbnailImageBig &&
                                                                post.item.postThumbnailImageBig.src
                                                            }
                                                            alt=""
                                                        />
                                                    </NavLinkAdv>
                                                </div>
                                                <div className="entry-info">
                                                    <div className="entry-header">
                                                        <h2 className="entry-title">
                                                            <NavLinkAdv
                                                                to={post.item && post.item.url}
                                                            >
                                                                {post.item.postTitle &&
                                                                    post.item.postTitle.value}
                                                            </NavLinkAdv>
                                                        </h2>
                                                    </div>
                                                    <div className="entry-meta icon-meta">
                                                        <span className="meta-date">
                                                            <i className="fa fa-calendar" />
                                                            <span>
                                                                <Moment format="MMM DD, YYYY">
                                                                    {post.item.date &&
                                                                        post.item.date.value}
                                                                </Moment>
                                                            </span>
                                                        </span>
                                                        <span className="meta-author">
                                                            <i className="fa fa-user" />
                                                            <NavLinkAdv to="#">
                                                                {post.item.postAuthor &&
                                                                    post.item.postAuthor.value}
                                                            </NavLinkAdv>
                                                        </span>
                                                        {post.item.postMainTag.targetItem &&
                                                            post.item.postMainTag.targetItem
                                                                .tagName && (
                                                                <span className="meta-category">
                                                                    <i className="fa fa-folder-open-o"></i>
                                                                    <NavLinkAdv
                                                                        to={
                                                                            tagsLink +
                                                                            '/?tagId=' +
                                                                            post.item.postMainTag
                                                                                .targetItem.id
                                                                        }
                                                                    >
                                                                        {post.item.postMainTag
                                                                            .targetItem &&
                                                                            post.item.postMainTag
                                                                                .targetItem.tagName
                                                                                .value}
                                                                    </NavLinkAdv>
                                                                </span>
                                                            )}
                                                    </div>
                                                    <div
                                                        className={
                                                            'widget widget_tag_cloud d-flex mb-2'
                                                        }
                                                    >
                                                        {post.item.postTagsList &&
                                                            post.item.postTagsList.targetItems.map(
                                                                (tag, idx) => (
                                                                    <React.Fragment key={idx}>
                                                                        <div className="tagcloud">
                                                                            <NavLinkAdv
                                                                                to={
                                                                                    tagsLink +
                                                                                    '/?tagId=' +
                                                                                    tag.id
                                                                                }
                                                                            >
                                                                                {tag && tag.name}
                                                                            </NavLinkAdv>
                                                                            &nbsp;
                                                                        </div>
                                                                    </React.Fragment>
                                                                )
                                                            )}
                                                    </div>
                                                    <div className="entry-content">
                                                        {post.item.postShortDescription &&
                                                            post.item.postShortDescription.value}
                                                    </div>
                                                    <div className="clearfix">
                                                        <div className="readmore-link">
                                                            <NavLinkAdv
                                                                to={post.item && post.item.url}
                                                            >
                                                                {i18next.t('read-more')}
                                                            </NavLinkAdv>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <p>There is no item to show.</p>
                                )}
                                <Pagination
                                    totalItems={totalItems}
                                    nbOfItemsToShow={this.state.nbOfItemsToShow}
                                    onChange={this.handleChange}
                                />
                            </div>
                        </div>
                    );
                }}
            </Query>
        );
    }
}

export default withSitecoreContext()(PostsList);
