import React from 'react';
import i18next from 'i18next';

class CartLink extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cartItems: '',
        };
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(event) {
        let sessionItems = sessionStorage.getItem('cartItems');
        let getSessionItems = sessionItems !== null ? sessionItems : '';

        this.setState({
            cartItems: getSessionItems,
        });

        if (getSessionItems) {
            let items = JSON.parse(getSessionItems);
            let idx = items.findIndex((i) => i.id == this.props.productid);
            if (idx >= 0) {
                items[idx].quantity = parseInt(items[idx].quantity) + parseInt('1');
            } else {
                let item = {
                    id: this.props.productid,
                    productname: this.props.productname,
                    price: this.props.price,
                    quantity: 1,
                    productimg: this.props.productimg,
                    producturl: this.props.producturl,
                };
                items.push(item);
            }
            this.setState({
                cartItems: JSON.stringify(items),
            });
            sessionStorage.setItem('cartItems', JSON.stringify(items));
        } else {
            let item = {
                id: this.props.productid,
                productname: this.props.productname,
                price: this.props.price,
                quantity: 1,
                productimg: this.props.productimg,
                producturl: this.props.producturl,
            };
            let items = [];
            items.push(item);
            this.setState({
                cartItems: JSON.stringify(items),
            });
            sessionStorage.setItem('cartItems', JSON.stringify(items));
        }
    }

    componentDidMount() {
        if (sessionStorage.getItem('cartItems')) {
            let sessionItems = sessionStorage.getItem('cartItems');
            let getSessionItems = sessionItems !== null ? sessionItems : '';

            if (getSessionItems !== '') {
                this.setState({
                    cartItems: getSessionItems,
                });
            }
        }
    }

    render() {
        const designVariant =
            typeof this.props.designVariant === 'undefined' ? '' : this.props.designVariant.value;
        return (
            <div className={'loop-add-to-cart ' + designVariant}>
                <a href="#" onClick={this.handleClick} className="add_to_cart_button">
                    {i18next.t('add-to-cart')}
                </a>
            </div>
        );
    }
}

export default CartLink;
