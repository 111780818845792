import React from 'react';
import { Image, RichText, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import ListProductVariant from '../ListProductVariant';
import StarRating from '../StarRating';
import i18next from 'i18next';
import ReactComponent from '../../ReactComponent';
import { loader as gqlLoader } from 'graphql.macro';
import { DotLoader } from 'react-spinners';
import { Query } from 'react-apollo';
import NavLinkAdv from '../../utils/NavLinkAdv';
import './ProductGridItemV2.scss';

const ProductGridQuery = gqlLoader('./query.graphql');

class ProductGridItemV2 extends ReactComponent {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            templateId: null,
        };

        this.activeRatingStatusId().then((res) => {
            this.setState({
                templateId: res.data.search.results.items[0].item.id.toLowerCase(),
            });
        });
    }

    componentDidMount() {}

    getRating(data) {
        let sumRating = 0;
        let numRating = 1;
        let averageRate = 0;
        // data.rating.numberValue && data.rating.numberValue.map((rating) => {
        //     numRating = data.productrating.results.items.length;
        //     sumRating += rating.item.rating.numberValue;
        // });
        averageRate = sumRating / numRating;

        return averageRate;
    }

    render() {
        const productId = this.props.product.item !== null ? this.props.product.item.id : '';

        if (this.state.templateId !== null) {
            return (
                <Query
                    query={ProductGridQuery}
                    variables={{
                        indexname: this.indexName,
                        rootpath: '/sitecore/content/' + this.appName + '/',
                        rootpathrating:
                            '/sitecore/content/' + this.appName + '/Content/Rating and Reviews',
                        productid: productId,
                        ratingstatus: this.state.templateId,
                    }}
                >
                    {({ loading, error, data }) => {
                        if (loading)
                            return (
                                <div
                                    className="sweet-loading"
                                    style={{ marginTop: '50px', marginBottom: '50px' }}
                                >
                                    <DotLoader
                                        sizeUnit={'px'}
                                        size={50}
                                        color={'#8986ca'}
                                        loading={!this.state.isLoaded}
                                    />
                                </div>
                            );
                        if (error) return <div>{i18next.t('error') + ': ' + error.message}</div>;

                        const product = this.props.product;
                        const columnsize = this.props.columnsize;

                        return (
                            product.item !== null && (
                                <>
                                    <li
                                        className={`col-md-${
                                            (columnsize && columnsize.value === '4') ||
                                            columnsize === null
                                                ? '3'
                                                : '4'
                                        } Producto-item text-center`}
                                        id={product.item.id.substr(5)}
                                    >
                                        <button
                                            type="button"
                                            className="plus__custom"
                                            data-toggle="modal"
                                            data-target={`#__${product.item.id.substr(5)}`}
                                        >
                                            <i className="fas fa-search-plus"></i>
                                        </button>

                                        <NavLinkAdv
                                            to={product.item.url}
                                            className="link__custom__item"
                                        >
                                            <span className="img-cn">
                                                <Image
                                                    loading="lazy"
                                                    field={product.item.image1}
                                                    width="600"
                                                    height="600"
                                                    className="img_custom_full"
                                                />
                                            </span>
                                            <strong className="title-cn">
                                                {product.item.title.value}
                                            </strong>
                                            <div className="star-cn">
                                                <StarRating
                                                    value={product.item.rating.numberValue}
                                                    isLittleSvg={true}
                                                />
                                            </div>
                                            <button type="button" className="btn btn_custom_o">
                                                {i18next.t('see-more')}
                                            </button>
                                        </NavLinkAdv>

                                        <div
                                            className="modal fade"
                                            id={`__${product.item.id.substr(5)}`}
                                            tabIndex="-1"
                                            role="dialog"
                                            aria-labelledby="label_of_modal"
                                            aria-hidden="true"
                                        >
                                            <div className="modal-dialog modal-dialog-centered modal-xl">
                                                <div className="modal-content">
                                                    <button
                                                        type="button"
                                                        className="close text-right"
                                                        data-dismiss="modal"
                                                        aria-label="Close"
                                                    >
                                                        <span aria-hidden="true">×</span>
                                                    </button>
                                                    <div className="modal-body row">
                                                        <div className="col-md-5 text-center">
                                                            <img
                                                                loading="lazy"
                                                                src={product.item.image1.src}
                                                                alt={product.item.image1.alt}
                                                                className="img_custom_full"
                                                            />
                                                        </div>
                                                        <div className="col-md-7 text-left">
                                                            <h2 className="popup-title_item">
                                                                {product.item.title.value}
                                                            </h2>
                                                            <span className="litro__custom">
                                                                <ListProductVariant
                                                                    productid={product.item.id}
                                                                />
                                                            </span>
                                                            <div className="star-cn">
                                                                <StarRating
                                                                    value={
                                                                        product.item.rating
                                                                            .numberValue
                                                                    }
                                                                    isLittleSvg={true}
                                                                />
                                                            </div>
                                                            <RichText
                                                                field={product.item.longDescription}
                                                                className="pop__description"
                                                            />
                                                            {data &&
                                                                data.productvariant &&
                                                                data.productvariant.results.items
                                                                    .length > 0 &&
                                                                (data.productvariant.results
                                                                    .items[0].item !== null &&
                                                                data.productvariant.results.items[0].item.productVariantLink.url.startsWith(
                                                                    'http'
                                                                ) === true ? (
                                                                    <a
                                                                        href={
                                                                            data.productvariant
                                                                                .results.items[0]
                                                                                .item
                                                                                .productVariantLink
                                                                                .url
                                                                        }
                                                                        className="btn btn_custom_o"
                                                                        target={'_blank'}
                                                                        rel="noopener noreferrer"
                                                                    >
                                                                        {i18next.t('see-more')}
                                                                    </a>
                                                                ) : (
                                                                    data.productvariant.results
                                                                        .items[0].item !== null && (
                                                                        <NavLinkAdv
                                                                            to={
                                                                                data.productvariant
                                                                                    .results
                                                                                    .items[0].item
                                                                                    .productVariantLink
                                                                                    .url
                                                                            }
                                                                            className="btn btn_custom_o"
                                                                            target="_blank"
                                                                        >
                                                                            {i18next.t('see-more')}
                                                                        </NavLinkAdv>
                                                                    )
                                                                ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </>
                            )
                        );
                    }}
                </Query>
            );
        } else {
            return false;
        }
    }
}

export default withSitecoreContext()(ProductGridItemV2);
