import React from 'react';
import './GoToTop.scss';

class GoToTop extends React.Component {
  

  componentDidMount() {
    //Events on scroll
    window.addEventListener("scroll", function() {

      const goToTopElement = document.querySelector(".go-to-top");
      const scrollTop = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;
      //go to top
      if(goToTopElement){
        if (scrollTop > 500) {
          goToTopElement.classList.add("on");
        } else {
            goToTopElement.classList.remove("on");
        }

        goToTopElement.addEventListener("click",this.scrollToTop, false);
      }
      
    });

    
  }


  scrollToTop = () => {
    window.scroll({top: 0, left:0, behavior:"smooth"})
  }

  render() {
    return(
      <a href="#" className="go-to-top"><i className="fa fa-angle-up"></i></a>
    );
  }
}

export default GoToTop;
