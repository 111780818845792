import React from 'react';
import {Placeholder, Text, withSitecoreContext} from '@sitecore-jss/sitecore-jss-react';
import './layoutWrapper.scss';

const ArticleLayoutWrapper = (props) => (
  <React.Fragment>
    <div className="containerArticle row-article-layout">
        <div className="col-md-8 pl-0">
            <Text field={props.sitecoreContext.route.fields.articleTitle} tag="h1" className={'title'}/>
            <Placeholder name="article-main-content" rendering={props.rendering} />
        </div>
        <div className="col-md-4 pr-0">
            <Placeholder name="article-sidebar-right" rendering={props.rendering}/>
        </div>
    </div>
 </React.Fragment>
);
export default withSitecoreContext()(ArticleLayoutWrapper);
