import React from 'react';
import { Text,withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import ParentRoute from '../../utils/ParentRoute';
import './Breadcrumbs.scss';


const Breadcrumbs = (props) => {
  
  return(
      <div className={"display__bread"}>
          <ParentRoute CurrentId={props.sitecoreContext.itemId} CurrentItemName={props.sitecoreContext.route.displayName} />
      </div>
    );
}
export default withSitecoreContext()(Breadcrumbs);
