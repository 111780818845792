import React from 'react';
import { RichText, Text, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import StarRating from '../StarRating';
import 'lightbox-react/style.css';
import NavLinkAdv from '../../utils/NavLinkAdv';
import ProductReviews from '../../utils/ProductReviews/index.js';
import i18next from 'i18next';
import './ProductGridItem.scss';
import { productClickTagging, handleClickToBuy } from '../Tagging';

// PROPS :
// - product (graphql product item, required)
// - listView (boolean, optional)
class ProductGridItem extends React.Component {
    render() {
        const product = this.props.product;

        const productUrl = product.item.url ? product.item.url : '#';
        const productCategory = product.item.productCategories.targetItems[0].title
            ? product.item.productCategories.targetItems[0].title.value
            : '';

        return (
            <div
                className="product-container productTag"
                data-gtm-name={product.item.title.value}
                data-gtm-ean={product.item.codeEan.value}
                data-gtm-category={productCategory}
                data-gtm-rating={product.item.rating.numberValue}
                data-gtm-list="list-result-range"
                data-gtm-position={this.props.index+1}
                data-gtm-observed="false"
                onClick={() =>
                    productClickTagging(
                        product.item.title.value,
                        product.item.codeEan.value,
                        productCategory,
                        product.item.rating.numberValue,
                        "list-result-range",
                        this.props.index+1
                    )
                }
            >
                <NavLinkAdv to={productUrl}>
                    <div className="product-images">
                        {product.item.image1 && (
                            <img
                                loading="lazy"
                                src={product.item.image1.src}
                                alt={product.item.image1.alt}
                            />
                        )}
                    </div>

                    <h3 className="product_title">
                        {product.item.title && product.item.title.value}
                    </h3>
                    {product.item.rating.numberValue !== 0 && (
                        <div className="info-meta clearfix">
                            <StarRating
                                value={product.item.rating.numberValue}
                                isLittleSvg={true}
                            />
                            <ProductReviews productId={product.item.id} />
                        </div>
                    )}

                    <div className="product_discover">{i18next.t('see-more')}</div> 
                </NavLinkAdv>
                {product.item.codeEan && (
                    <div className="buy-btn">
                        <a onClick={() => handleClickToBuy(product.item.title.value, product.item.codeEan.value)} data-eans={product.item.codeEan.value} className="btn swn-tag-wtb-btn">
                            {i18next.t('Acheter')}
                        </a>
                    </div>
                )}
            </div>
        );
    }
}

export default withSitecoreContext()(ProductGridItem);
