import React from 'react';
import { NavLink } from 'react-router-dom';
import NavLinkAdv from '../../utils/NavLinkAdv';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

import i18next from 'i18next';

const FooterMenu = (props) => {
    const datasource = props.fields || {};
    if (datasource != undefined && Object.getOwnPropertyNames(datasource).length >= 1) {
        const { SiteLanguages, footerMenuLinks } = props.fields;
        const designVariant =
            typeof props.fields.designVariant === 'undefined'
                ? ''
                : props.fields.designVariant.value;

        return (
            <div className={'content__wrapper ' + designVariant}>
                <footer>
                    <div className="content__footer">
                        <div className="content__footer-language-selector">
                            <select name="changeLanguage" id="changeLanguage">
                                {SiteLanguages &&
                                    SiteLanguages.map((language, index) => (
                                        <option
                                            key={`option-${index}`}
                                            value={`${language.fields.LanguageTitle.value}`}
                                        >
                                            {language.fields.LanguageTitle.value}
                                        </option>
                                    ))}
                            </select>
                        </div>
                        <ul className="content__footer-menu">
                            {footerMenuLinks &&
                                footerMenuLinks.map((listItem, index) => (
                                    <li key={`footermenuitem-${index}`}>
                                        <NavLinkAdv
                                            to={
                                                listItem.fields.InternalLink &&
                                                listItem.fields.InternalLink.value.href
                                            }
                                        />
                                    </li>
                                ))}
                        </ul>
                    </div>
                </footer>
            </div>
        );
    } else {
        return (
            <div className="component-no-datasource">
                {i18next.t('no-datasource') +
                    ' ' +
                    this.props.rendering.componentName +
                    ' ' +
                    i18next.t('component')}
            </div>
        );
    }
};

export default withSitecoreContext()(FooterMenu);
