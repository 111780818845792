import React from 'react';
import { Image, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import i18next from 'i18next';
import ReactComponent from '../../ReactComponent';
import { loader as gqlLoader } from 'graphql.macro';
import NavLinkAdv from '../../utils/NavLinkAdv';

const SocialMediaArticleQuery = gqlLoader('./query.graphql');

class SocialMediaArticles extends ReactComponent {
    render() {
        return (
            <div className="row_custom_small">
                <div style={{ margin: 'auto' }} className="link_social">
                    {this.props.fields.Links.map(function (sociallink, idx) {
                        return (
                            <div style={{ display: 'inline-block' }} key={idx}>
                                {sociallink.fields.InternalLink.value.href.startsWith('http') ? (
                                    <a
                                        href={sociallink.fields.InternalLink.value.href}
                                        className="text-dark"
                                    >
                                        <Image
                                            loading="lazy"
                                            field={sociallink.fields.Image}
                                            className="img_custom_full"
                                        />
                                    </a>
                                ) : (
                                    <NavLinkAdv
                                        to={sociallink.fields.InternalLink.value.href}
                                        className="text-dark"
                                    >
                                        <Image
                                            loading="lazy"
                                            field={sociallink.fields.Image}
                                            className="img_custom_full"
                                        />
                                    </NavLinkAdv>
                                )}
                            </div>
                        );
                    })}
                    <span className="border-0">{i18next.t('LiveRawLabel')}</span>
                </div>
            </div>
        );
    }
}

export default withSitecoreContext()(SocialMediaArticles);
