import React from 'react';
import { Image, Text, RichText, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import Moment from 'react-moment';
import { Query } from 'react-apollo';
import ReactComponent from '../../ReactComponent';
import { loader as gqlLoader } from 'graphql.macro';
import NavLinkAdv from '../../utils/NavLinkAdv';
import i18next from 'i18next';

const PostContentQuery = gqlLoader('./PostContentQuery.graphql');

class PostContent extends ReactComponent {
    render() {
        const postContent = this.props.sitecoreContext.route.fields;
        const contextItem = this.props.sitecoreContext.route.itemId;

        let postNav = true;
        if (postContent.PostPrevious == null && postContent.PostNext == null) {
            postNav = false;
        }

        return (
            <>
                {postContent && (
                    <>
                        <div className={'single '}>
                            <div className="hentry">
                                <div className="hentry-wrap">
                                    <div className="entry-featured">
                                        <Image
                                            loading="lazy"
                                            field={postContent.PostThumbnailImageBig}
                                            imageParams={{ w: 970, h: 540 }}
                                            editable={true}
                                        />
                                    </div>
                                    <div className="entry-info">
                                        <div className="entry-header">
                                            <Text
                                                field={postContent.PostTitle}
                                                tag="h1"
                                                className="entry-title"
                                            />
                                            <div className="entry-meta icon-meta">
                                                <span className="meta-date">
                                                    <i className="fa fa-calendar" />
                                                    <Query
                                                        query={PostContentQuery}
                                                        variables={{
                                                            rootPath:
                                                                '/sitecore/content/' +
                                                                this.appName +
                                                                '/home/',
                                                            id: contextItem
                                                                .split('-')
                                                                .join('')
                                                                .toLowerCase(),
                                                        }}
                                                    >
                                                        {({ loading, error, data }) => {
                                                            if (loading)
                                                                return (
                                                                    <div>
                                                                        {i18next.t('loading') +
                                                                            ': ' +
                                                                            loading.message}
                                                                    </div>
                                                                );
                                                            if (error)
                                                                return (
                                                                    <div>
                                                                        {i18next.t('error') +
                                                                            ': ' +
                                                                            error.message}
                                                                    </div>
                                                                );

                                                            let date =
                                                                data.customSearch.results.items[0]
                                                                    .item.date.value;

                                                            return (
                                                                <Moment format="MMM DD, YYYY">
                                                                    {date}
                                                                </Moment>
                                                            );
                                                        }}
                                                    </Query>
                                                </span>
                                                <span className="meta-author">
                                                    <i className="fa fa-user" />
                                                    <NavLinkAdv to="#">
                                                        <Text field={postContent.PostAuthor} />
                                                    </NavLinkAdv>
                                                </span>
                                                <span className="meta-category">
                                                    <i className="fa fa-folder-open-o" />
                                                    {postContent.PostMainTag && (
                                                        <NavLinkAdv
                                                            to={
                                                                '/Posts-Grid/?tagId=' +
                                                                postContent.PostMainTag.id.replace(
                                                                    /-/g,
                                                                    ''
                                                                )
                                                            }
                                                        >
                                                            <Text
                                                                field={
                                                                    postContent.PostMainTag.fields
                                                                        .TagName
                                                                }
                                                                editable={false}
                                                            />
                                                        </NavLinkAdv>
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="entry-content">
                                            <RichText field={postContent.PostContent} />
                                        </div>
                                        <div className="entry-footer">
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="entry-tags">
                                                        <span>{i18next.t('tags') + ': '}</span>
                                                        {postContent.PostTagsList &&
                                                            postContent.PostTagsList.map(
                                                                (tag, index) => {
                                                                    return (
                                                                        <NavLinkAdv
                                                                            key={index}
                                                                            to={
                                                                                '/Posts-Grid/?tagId=' +
                                                                                tag.id.replace(
                                                                                    /-/g,
                                                                                    ''
                                                                                )
                                                                            }
                                                                        >
                                                                            <Text
                                                                                field={
                                                                                    tag.fields
                                                                                        .TagName
                                                                                }
                                                                                editable={false}
                                                                            />
                                                                        </NavLinkAdv>
                                                                    );
                                                                }
                                                            )}
                                                    </div>
                                                </div>
                                                {/* Social links component */}
                                                <div className="col-sm-6">
                                                    <div className="share-links">
                                                        <span>Share:</span>
                                                        <div className="share-icons">
                                                            <span className="facebook-share">
                                                                <a
                                                                    href="#"
                                                                    title="Share on Facebook"
                                                                >
                                                                    <i className="fa fa-facebook" />
                                                                </a>
                                                            </span>
                                                            <span className="twitter-share">
                                                                <a
                                                                    href="#"
                                                                    title="Share on Twitter"
                                                                >
                                                                    <i className="fa fa-twitter" />
                                                                </a>
                                                            </span>
                                                            <span className="google-plus-share">
                                                                <a
                                                                    href="#"
                                                                    title="Share on Google +"
                                                                >
                                                                    <i className="fa fa-google-plus" />
                                                                </a>
                                                            </span>
                                                            <span className="linkedin-share">
                                                                <a
                                                                    href="#"
                                                                    title="Share on Linked In"
                                                                >
                                                                    <i className="fa fa-linkedin" />
                                                                </a>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {postNav && (
                                <nav className="post-navigation">
                                    <div className="row">
                                        {postContent.PostPrevious && (
                                            <div className="col-sm-6">
                                                <div className="prev-post">
                                                    <span>{i18next.t('previous-article')}</span>
                                                    <NavLinkAdv
                                                        to={postContent.PostPrevious.url}
                                                        rel="prev"
                                                    >
                                                        {
                                                            postContent.PostPrevious.fields
                                                                .PostTitle.value
                                                        }
                                                    </NavLinkAdv>
                                                </div>
                                            </div>
                                        )}
                                        {postContent.PostNext && (
                                            <div className="col-sm-6">
                                                <div className="next-post">
                                                    <span>{i18next.t('next-article')}</span>
                                                    <NavLinkAdv
                                                        to={postContent.PostNext.url}
                                                        rel="next"
                                                    >
                                                        {
                                                            postContent.PostNext.fields.PostTitle
                                                                .value
                                                        }
                                                    </NavLinkAdv>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </nav>
                            )}
                        </div>
                    </>
                )}
            </>
        );
    }
}

export default withSitecoreContext()(PostContent);
