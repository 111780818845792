import React from 'react';
import './Pagination.scss';

class Pagination extends React.Component {
    state = {
        pagination: [],
        numberOfPages: null,
    };

    componentDidMount() {
        this.Pagination(this.props.totalItems);
    }
    
    componentDidUpdate(nextProps) {
        if (nextProps.totalItems !== this.props.totalItems || this.props.refreshPagination) {
            this.Pagination(this.props.totalItems);
            this.handleChangeState(1);
        }
    }
    
    handleChangeState(nb) {
        let data = {
            after: ((nb - 1) * parseInt(this.props.nbOfItemsToShow)).toString(),
            currentPage: nb,
        };
        this.props.onChange(data);
    }
    
    Pagination(total) {
        this.setState({ pagination: [] });
        let pagesNumber = total / parseInt(this.props.nbOfItemsToShow);
        let numberOfPages;
        if (pagesNumber % 2 === 0) {
            numberOfPages = pagesNumber + 1;
        } else {
            numberOfPages = Math.floor(pagesNumber) + 1;
        }
        this.setState({ numberOfPages: numberOfPages });

        let tempPagination = []
        for (let i = 0; i < numberOfPages; i++) {
            tempPagination.push(i + 1);
        }
        this.setState({pagination: tempPagination})
    }
    
    render() {
        return (
            <div className="col-md-12 text-center Pagination">
                {this.props.currentPage !== 1 && this.state.numberOfPages > 3 && (
                    <button
                        className="prev"
                        onClick={() => this.handleChangeState(this.props.currentPage - 1)}
                    >
                        Précedente
                    </button>
                )}
                {this.props.totalItems > 0 ? (
                    this.state.pagination.map((nb, index) => {
                        if (nb >= this.props.currentPage - 2 && nb <= this.props.currentPage + 2) {
                            return (
                                <React.Fragment key={index}>
                                    <a
                                        href={'#'}
                                        onClick={() => {
                                            this.handleChangeState(nb);
                                            window.scrollTo(0, 0);
                                        }}
                                        className={
                                            this.props.currentPage === nb
                                                ? 'active number'
                                                : 'number'
                                        }
                                    >
                                        {nb}
                                    </a>
                                </React.Fragment>
                            );
                        }
                    })
                ) : (
                    <p>Il n'y a pas de produits correspondant à ces critères</p>
                )}
                {this.props.currentPage !== this.state.numberOfPages &&
                    this.state.numberOfPages > 3 && (
                        <button
                            className="next"
                            onClick={() => this.handleChangeState(this.props.currentPage + 1)}
                        >
                            Suivante
                        </button>
                    )}
            </div>
        );
    }
}

export default Pagination;
