import React from 'react';
import { Text, Image, RichText } from '@sitecore-jss/sitecore-jss-react';
import NavLinkAdv from "../../NavLinkAdv";

class ArticlePush extends React.Component {
    render() {
        return(
            <div className={"first_article_navigation resize"}>
                <div className="left-part">
                    <Image field={this.props.image} />
                </div>
                <div className="right-part">
                    <Text field={this.props.categoryName} tag={"span"} className={"category"}/>
                    <Text field={this.props.title} tag={"h2"}/>
                    <RichText field={this.props.description} tag={"div"} className={"content"}/>
                    <NavLinkAdv to={this.props.url} className={"link"}>
                        {this.props.label}
                    </NavLinkAdv>
                </div>
            </div>
        )
    }
}

export default ArticlePush;
