import React from 'react';
import {Text, withSitecoreContext} from '@sitecore-jss/sitecore-jss-react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import ReactComponent from "../../ReactComponent";

const Range = Slider.Range;

class FilterPrice extends ReactComponent {

  constructor(props) {
    super(props);
    this.state = {
      valueMin: 0,
      valueMax: "",
      displayMin: "",
      displayMax: "",
      reseted: false,
    };
    this.onSliderChange = this.onSliderChange.bind(this);
  }

  componentDidMount() {
    this.getMaxPriceForRange();
  }

  componentDidUpdate(prevProps, prevState) {
    if ((prevState.displayMin !== this.state.displayMin || prevState.displayMax !== this.state.displayMax) && prevState.displayMin === -1 && prevState.displayMax === -1) {
      this.props.onRangeChange([this.state.displayMin, this.state.displayMax]);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.facets !== null && nextProps.facets !== this.props.facets && nextProps.facets.length !== 0 && nextProps.updateRange) {
      let priceFacets = [];
      for(let facet of nextProps.facets) {
        if (facet.name === "saleprice") {
          priceFacets = facet.values;
        }
      }
      if (priceFacets.length !== 0) {
        let min = parseFloat(priceFacets[0].value), max = parseFloat(priceFacets[0].value);
        for (let i = 1, len=priceFacets.length; i < len; i++) {
          if (priceFacets[i].value !== 0.0) {
            let v = parseFloat(priceFacets[i].value);
            min = (v < min) ? v : min;
            max = (v > max) ? v : max;
          }
        }
        
        if (this.state.reseted === true) {
          this.setState({
            valueMin: Math.floor(min),
            valueMax: Math.ceil(max),
            initMin: Math.floor(min),
            initMax: Math.ceil(max),
            displayMin: Math.floor(min),
            displayMax: Math.ceil(max),
            reseted: false,
          });
        } else if (nextProps.range[0] === "*" && nextProps.range[1] === "*") {
          
          if (!(this.state.displayMin === -1 && this.state.displayMax === -1)) {
            this.props.onRangeChange([this.state.displayMin, this.state.displayMax]);
          }
          
          this.setState({
            valueMin: Math.floor(min),
            valueMax: Math.ceil(max),
            initMin: this.state.initMin === undefined ? Math.floor(min) : this.state.initMin,
            initMax: this.state.initMax === undefined ? Math.ceil(max) : this.state.initMax,
            displayMin: this.state.initMin === undefined ? Math.floor(min) : this.state.displayMin,
            displayMax: this.state.initMax === undefined ? Math.ceil(max) : this.state.displayMax,
          });
          
        }
        else {
          let range = ["*","*"];
          let updateValueMax = false;
          let updateValueMin = false;
          if (nextProps.range[0] === "*") range[0] = Math.floor(min);
          else if (nextProps.range[0] < min) {
            range[0] = min;
            updateValueMin = true;
          }
          else range[0] = nextProps.range[0];
          if (nextProps.range[1] === "*") range[1] = Math.floor(max);
          else if (nextProps.range[1] > max) {
            range[1] = max;
            updateValueMax = true;
          }
          else range[1] = nextProps.range[1];
          this.setState({
            displayMin: range[0],
            displayMax: range[1],
            valueMin: updateValueMin ? range[0] : this.state.valueMin,
            valueMax: updateValueMax ? range[1] : this.state.valueMax,
          });
        }
      } else {
        this.setState({
          displayMin: 0,
          displayMax: 0,
          valueMin: 0,
          valueMax: 0,
        });
      }
      
    }
    if (nextProps.resetFilters !== this.props.resetFilters) {
      this.setState({
        valueMin: this.state.initMin,
        valueMax: this.state.initMax,
        displayMin: this.state.initMin,
        displayMax: this.state.initMax,
        reseted : true,
      })
    }
  }

  getMaxPriceForRange() {
    this.maxPrice().then(data => {
      this.setState({
        valueMax: parseInt(data.data.maxProductsPrice),
        displayMax: parseInt(data.data.maxProductsPrice),
        displayMin: 0
      });
    })
  }

  toReset() {
    this.getMaxPriceForRange();
  }

  onSliderChange(value) {
    this.setState({
      displayMin: value[0],
      displayMax: value[1]
    });
    this.props.onRangeChange(value);
  }

  render() {
    if(this.state.displayMax === undefined && this.state.displayMin === undefined) {
      this.toReset();
    }

    return(
      <div className={"widget commerce widget_price_filter"}>
        <div className="price_slider_wrapper">
          <Text field={this.props.fields.title} tag="h4" className="widget-title" />
          <Range 
            allowCross={true} 
            min={this.state.valueMin}
            max={this.state.valueMax}
            defaultValue={[this.state.valueMin, this.state.valueMax]} 
            value={[this.state.displayMin, this.state.displayMax]}
            onChange={this.onSliderChange}
            className="price_slider"
            handleStyle={[{width: '14px',height: '14px',outline: 'none',
              background: '#333',borderRadius: 0,border: '1px dotted #333'},
              {width: '14px',height: '14px',outline: 'none',
              background: '#333',borderRadius: 0,border: '1px dotted #333'}]}
            trackStyle={[{border: 0,background: '#999'}]}
          />
          <div className="price_slider_amount">
            <div className="price_label">
              Price: <span className="from">${this.state.displayMin}</span> - <span className="to">${this.state.displayMax}</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
  
}

export default withSitecoreContext()(FilterPrice);
