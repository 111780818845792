import React, { useEffect, useState } from 'react';
import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import './CustomPopIn.scss';
import Cookies from 'js-cookie';

class CustomPopIn extends React.Component {
    state = {
        popinState: null,
    };

    closePopIn() {
        Cookies.set('openCustomPopin', false);
        this.setState({ popinState: 'closed' });
    }

    handlePopin() {
        if (this.state.popinState === 'open') {
            document.querySelector('body').classList.add('popinOpened');
            document.querySelector('body').style.overflow = 'hidden';
        } else {
            document.querySelector('body').classList.remove('popinOpened');
            document.querySelector('body').style.overflow = 'auto';
        }
    }

    componentDidUpdate() {
        this.handlePopin();
    }

    componentDidMount() {
        this.handlePopin();
        this.setState({ popinState: Cookies.get('openCustomPopin') ? 'closed' : 'open' });
    }

    render() {
        return (
            <div id="CustomPopInContainer" className={this.state.popinState}>
                <div className="CustomPopIn">
                    <div
                        onClick={() => this.closePopIn()}
                        className="closeButton"
                        role="button"
                        title="close"
                    >
                        X
                    </div>
                    <h2>
                        <Text field={this.props.fields.title} />
                    </h2>
                    <RichText className="content" tag="div" field={this.props.fields.content} />
                </div>
            </div>
        );
    }
}

export default CustomPopIn;


























