import React from 'react';
import RowBlock from '../RowBlock/index';
import './parentRowBlock.scss';

const ParentRowBlock = (props) => (
    // Title and Subtitle can be used here
    <div className={'parentRowBlock'}>
        {
            props.fields.rowsChild.map((item, idx) => {
                return(
                    <RowBlock rows={item} key={idx}/>
                )
            })
        }
    </div>
);

export default ParentRowBlock;
