import React, { useEffect } from 'react';
import { Text, RichText, Link, Image, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import NavLinkAdv from '../../utils/NavLinkAdv';
import './blockImgLeft.scss';
import i18next from 'i18next';

const BlockImageLeft = (props) => {
    const datasource = props.fields || {};
    let designVariant = props.fields.designVariant && props.fields.designVariant.value;

    let classesBlock;

    if (props.fields.BackgroundImage !== undefined) {
        classesBlock = {
            backgroundImage: `url(` + props.fields.BackgroundImage.value.src + `)`,
            backgroundRepeat: `no-repeat`,
            backgroundSize: `cover`,
        };
    }

    if (datasource !== undefined && Object.getOwnPropertyNames(datasource).length >= 1) {
        return (
            <div className={'section blockImgLeft ' + designVariant + ''}>
                {' '}
                {/* Add this : style={classesBlock} to have background image (need to do the css) */}
                <div className="row">
                    <div className="block-right">
                        <Text field={props.fields.Title} tag="h2" className="title" />
                        <RichText tag="p" field={props.fields.Description} className={'content'} />
                        {props.fields.Link.value.href.length > 0 && (
                            <NavLinkAdv
                                to={props.fields.Link && props.fields.Link.value.href}
                                className="link"
                            >
                                <span>{props.fields.Link.value.text}</span>
                                <i className="fa fa-angle-right" />
                            </NavLinkAdv>
                        )}
                    </div>
                    <div className="block-left">
                        <Image loading="lazy" field={props.fields.Image} />
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div className="component-no-datasource">
                {i18next.t('no-datasource') +
                    ' ' +
                    props.rendering.componentName +
                    ' ' +
                    i18next.t('component')}
            </div>
        );
    }
};

export default withSitecoreContext()(BlockImageLeft);
