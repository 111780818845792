import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import './overhead.scss';
import NavLinkAdv from "../../utils/NavLinkAdv";

const OverHead = (props) => {
  const {overHeadBannerContentList, designVariant} = props.fields;
  return (
      <div className={"row_custom_small tool-hd "+designVariant}>
          <div className="col-12">
                { overHeadBannerContentList &&    
                    overHeadBannerContentList.map((listItem, index) => (
                      <React.Fragment key={index}>
                      {listItem.fields.InternalLink && listItem.fields.InternalLink.value.href.startsWith("http") === true ?
                        <a href={listItem.fields.InternalLink.value.href} key={index}><i className={listItem.fields.IconClass.value} aria-hidden="true"/><Text field={listItem.fields.Title} /></a>
                      :
                        <NavLinkAdv to={listItem.fields.InternalLink.value.href} key={index}><i className={listItem.fields.IconClass.value} aria-hidden="true"/><Text field={listItem.fields.Title} /> </NavLinkAdv>
                      }
                      </React.Fragment>
                  ))}  
          </div>
      </div>
  );
}

export default OverHead;
