import React from 'react';
import {Placeholder} from '@sitecore-jss/sitecore-jss-react';
import {StickyContainer} from "react-sticky";

class FiltersCategoriesLayoutWrapper extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            filters: []
        };
        this.onFiltersChange = this.onFiltersChange.bind(this);
    }

    onFiltersChange(tab) {
        this.setState({
            filters: [tab]
        });
    }

    render() {
        return (
            <div className="container">
                <StickyContainer className="row">
                    <div className="col-md-12 text-center d-flex">
                        <Placeholder name="main-top" rendering={this.props.rendering} onFiltersChange={this.onFiltersChange}/>
                    </div>
                    <div className="col-md-12">
                        <Placeholder name="main-bottom" rendering={this.props.rendering} filters={this.state.filters}/>
                    </div>
                </StickyContainer>
            </div>
        )
    }
}

export default FiltersCategoriesLayoutWrapper;
