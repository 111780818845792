import React from 'react'

const styles = {
    container: {
        flexDirection: 'row', 
        display: 'flex',
        alignItems: 'center', 
        justifyContent: 'center', 
        margin: 20
    },
    move: {
        transition: 'margin .5s',
        margin: '0 0 0 -250'
    }
}

const Pagination = (props) => {
    const { activePage, nbPage, activeBackgroundColor, hoverBackgroundColor, fontSize, spacing, digitWidth, colors, iconSize } = props;
    const [active, setActive] = React.useState(activePage);
    const [inGrid, setInGrid] = React.useState([active -2, active -1, active, active + 1, active + 2]);
    const [hiddenBefore, sethHiddenBefore] = React.useState(active > 3);
    const [hiddenAfter, sethHiddenAfter] = React.useState(active < nbPage - 2);
    const pages = [];
    const [isClicked, setIsClicked] = React.useState(false);

    React.useEffect(() => {
        setInGrid([active -2, active -1, active, active + 1, active + 2]);
        sethHiddenBefore(active > 3);
        sethHiddenAfter(active < nbPage - 2);
    }, [active]);


    for (let i = 1; i <= nbPage; i++) {
       pages.push(i);
    }

    const changePageWithDigit = (page) => {
        setActive(page)
        props.handleChange(page)
    }

    const Item = ({page}) => {
        const [isActive, setIsActive] = React.useState(page === active);
        const [isHovered, setIsHovered] = React.useState(false);

        return (
            <div 
                style={{
                    marginLeft : spacing, 
                    color: isActive ? 'white' : 'black', 
                    backgroundColor: isActive ? activeBackgroundColor : (isHovered ? hoverBackgroundColor : ''), 
                    width: digitWidth,
                    fontSize: fontSize,
                    borderRadius: digitWidth/2,
                }} 
                onClick={() => changePageWithDigit(page)}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                <div style={{ marginLeft: digitWidth * 0.35}}>
                    {page}
                </div>
            </div>
        )
    }

    return (
        <>
            <div style={styles.container}>

                {
                    nbPage > 5 ?                 
                        <i className={`fas fa-fast-backward ${iconSize.small}`}
                        onClick={() => {
                            setActive(1);
                            props.handleChange(1);
                        }}
                        style={{color: colors.iconColor}} 
                    ></i> : null
                }
                <i className={`fas fa-step-backward ${iconSize.small}`}                     
                    onClick={() => {
                        let increment = active - 1;
                        if (pages.includes(increment)) {
                            setActive(increment)
                            props.handleChange(increment);
                        }
                    }}
                    style={{marginLeft: spacing, color: colors.iconColor}}
                ></i>
                { hiddenBefore ? <div style={{fontSize: fontSize, marginLeft: spacing}}>...</div> : null }
                {
                    pages.filter(p => inGrid.includes(p)).map(page => {
                        return <Item page={page} key={Math.random().toString()}/>
                    })
                }
                { hiddenAfter ? <div style={{fontSize: fontSize, marginLeft: spacing}}>...</div> : null }

                <i className={`fas fa-step-forward ${iconSize.small}`}                      
                    onClick={() => {
                        let increment = active + 1;
                        if (pages.includes(increment)) {
                            setActive(active => active + 1)
                            props.handleChange(active + 1)
                            }
                    }}
                    style={{marginLeft: spacing, color: colors.iconColor}} 
                ></i>                  
                {
                    nbPage > 5 ? (
                        <i className={`fas fa-fast-forward ${iconSize.small}`}   
                    onClick={() => {
                        setActive(pages.length);
                        props.handleChange(pages.length);
                    }}
                    style={{marginLeft: spacing, color: colors.iconColor }}
                ></i>
                    ) : null
                }

            </div>
        </>
    )
}

export default Pagination;