import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import InstagramCarouselItem from '../InstagramCarouselItem';
import './InstagramCarousel.scss';
import { loader as gqlLoader } from 'graphql.macro';
import { Query } from 'react-apollo';
import ReactComponent from '../../ReactComponent';
import { withSitecoreContext, Text } from '@sitecore-jss/sitecore-jss-react';
import i18next from 'i18next';

const LatestPostsQuery = gqlLoader('./GetInstagramImages.graphql');

class InstagramCarousel extends ReactComponent {
    constructor(props) {
        super(props);
        const datasource = this.props.fields || {};
        if (datasource !== undefined && Object.getOwnPropertyNames(datasource).length >= 1) {
            this.state = {
                numberOfImagesToShow: props.fields.NumberOfImages.value,
                numberOfImagesToLoad: props.fields.NumberOfImagesToLoad.value,
            };
        }
    }

    render() {
        const datasource = this.props.fields || {};
        if (datasource !== undefined && Object.getOwnPropertyNames(datasource).length >= 1) {
            const { numberOfImagesToShow, numberOfImagesToLoad } = this.state;
            const settings = {
                centerMode: true,
                centerPadding: '60px',
                dots: true,
                infinite: true,
                speed: 500,
                slidesToShow: parseInt(numberOfImagesToShow),
                draggable: true,
                responsive: [
                    {
                        breakpoint: 769,
                        settings: {
                            slidesToShow: 1,
                        },
                    },
                ],
            };

            return (
                <div>
                    <Query
                        query={LatestPostsQuery}
                        variables={{
                            rootItem: '/sitecore/content/' + this.appName + '/',
                            index: this.indexName,
                        }}
                    >
                        {({ loading, error, data }) => {
                            if (loading)
                                return <div>{i18next.t('loading') + ': ' + loading.message}</div>;
                            if (error)
                                return <div>{i18next.t('error') + ': ' + error.message}</div>;

                            return (
                                <div className="instagram-recent-media">
                                    <div className="col-md-12 text-center isMob">
                                        <Text
                                            field={this.props.fields && this.props.fields.Title}
                                            tag="h2"
                                        />
                                    </div>
                                    <Slider {...settings}>
                                        {data.LatestPostsList.results &&
                                            data.LatestPostsList.results.items
                                                .filter(
                                                    (post) =>
                                                        post.item !== null &&
                                                        !post.item.imageMediaUrl.value.includes(
                                                            'video'
                                                        )
                                                ) //remove videos from the slider
                                                .sort((x, y) => {
                                                    return x.item.imageTimestamp.value ===
                                                        y.item.imageTimestamp.value
                                                        ? 0
                                                        : x.item.imageTimestamp.value <
                                                          y.item.imageTimestamp.value
                                                        ? 1
                                                        : -1;
                                                })
                                                .slice(0, numberOfImagesToLoad)
                                                .map((post, index) => (
                                                    <div key={index}>
                                                        <InstagramCarouselItem item={post.item} />
                                                    </div>
                                                ))}
                                    </Slider>
                                </div>
                            );
                        }}
                    </Query>
                </div>
            );
        } else {
            return (
                <div className="component-no-datasource">
                    {i18next.t('no-datasource') +
                        ' ' +
                        this.props.rendering.componentName +
                        ' ' +
                        i18next.t('component')}
                </div>
            );
        }
    }
}

export default withSitecoreContext()(InstagramCarousel);
