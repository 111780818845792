import React from 'react';
import { RichText, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import './richTextComponent.scss';

class RichTextComponent extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    return(
      <div className="richText">
        <div className="richtext_custom">
          <RichText field={this.props.fields && this.props.fields.Text}/>
        </div>
      </div>
    )
  }
}

export default withSitecoreContext()(RichTextComponent);
